* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Timer {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 100vh;
}

.new-year-container {
  text-align: center;
  width: inherit;
}

.new-year-container h2 {
  width: inherit;
  font-size: 50px;
  margin: 15px 0;
  color: rgb(255, 0, 0);
}

.time-box {
  display: flex;
  width: inherit;
  text-align: center;
  justify-content: center;

}

.time-box h2,
p {
  width: inherit;
}

.days,
.hours,
.minutes,
.seconds {
  font-size: 25px;
  color: #fff;
  margin: 0 10px;
  padding: 10px;
  animation: animate 3s linear infinite;
  text-shadow: 0 0 50px #0072ff, 0 0 100px #0072ff, 0 0 150px #0072ff,
    0 0 200px #0072ff;
}

.hours {
  animation-delay: 0.3s;
}

.minutes {
  animation-delay: 0.7s;
}

.seconds {
  animation-delay: 0.1s;
}

@keyframes animate {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}


@media only screen and (max-width: 600px) {
  .new-year-container {
    text-align: center;
    width: inherit;
  }
  
  .new-year-container h2 {
    width: inherit;
    font-size: 40px;
    margin: 10px;
    color: rgb(255, 0, 0);
  }
  
  .time-box {
    display: flex;
    width: 200px;
    text-align: center;
    justify-content: center;
    margin-left: 20px;

  
  }
  
  .time-box h2,
  p {
    width: inherit;
  }
  
  .days,
  .hours,
  .minutes,
  .seconds {
    font-size: 15px;
    color: #fff;
    margin: 0 3px;
    padding: 5px;
    animation: animate 3s linear infinite;
    text-shadow: 0 0 50px #0072ff, 0 0 100px #0072ff, 0 0 150px #0072ff,
      0 0 200px #0072ff;
    justify-content: center;
  }
  
  
}